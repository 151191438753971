import { Component } from 'js-component-framework';
import getClosest from 'utils/getClosest';
import isDesktopView from 'utils/isDesktopView';

/**
 * Component for Article Meta
 */
export default class ArticleMeta extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    this.setMetaElements();
    this.toggleAttributes();
  }

  /**
   * Find the article meta elements.
   */
  setMetaElements() {
    if (getClosest(this.element, '.article-header--wrap')) {
      this.headerMeta = getClosest(this.element, '.article-header--wrap');
    }

    if (getClosest(this.element, '.article-content--wrap')) {
      this.contentMeta = getClosest(this.element, '.article-content--wrap');
    }
  }

  /**
   * Toggle the meta elements attributes.
   */
  toggleAttributes() {
    if (isDesktopView()) {
      if (this.headerMeta) {
        this.element.setAttribute('aria-hidden', true);
      }

      if (this.contentMeta) {
        this.element.setAttribute('aria-hidden', false);
      }
    } else {
      if (this.headerMeta) {
        this.element.setAttribute('aria-hidden', false);
      }

      if (this.contentMeta) {
        this.element.setAttribute('aria-hidden', true);
      }
    }
  }
}
