/**
 * Find the closest element.
 *
 * @param {Object} elem Element of origin.
 * @param {String} selector Selector of the element to find.
 */
export default function (elem, selector) { // eslint-disable-line func-names
  for (; elem && elem !== document; elem = elem.parentNode) { // eslint-disable-line no-param-reassign
    if (elem.matches(selector)) return elem;
  }
  return null;
}
