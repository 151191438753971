/**
 * Article global JS.
 */
// Instantiate components
// Components
import { ComponentManager } from 'js-component-framework';
import shareButtonsConfig from 'components/share-buttons';
import articleConfig from 'components/article';
import articleMetaConfig from 'components/article-meta';
import domContentLoaded from 'utils/domContentLoaded';
import reactComponentManager from 'utils/reactComponentManager';
import NBCTruncate from 'components/nbc-truncate';
import videoFlyoutConfig from 'components/video-flyout';

// Import SCSS
import 'scss/article/index.scss';

const manager = new ComponentManager('nbc-article-components');

domContentLoaded(() => {
  manager.initComponents([
    shareButtonsConfig,
    articleConfig,
    articleMetaConfig,
    videoFlyoutConfig,
  ]);

  reactComponentManager({
    NBCTruncate,
  });
});

if (module.hot) {
  module.hot.accept();
  // This is required for HMR, otherwise you'll need to refresh
  // the page to see JS changes
  manager.initComponents([
    shareButtonsConfig,
    articleConfig,
    articleMetaConfig,
    videoFlyoutConfig,
  ]);

  reactComponentManager({
    NBCTruncate,
  });
}
