/**
   * Handle a service click.
   *
   * Abstracted to support reusability in other components.
   *
   * @param {Object} event click event.
   * @param {String} service Service name.
   * @param {String} href Anchor href.
   */
const onServiceClick = (event, service, href) => {
  event.preventDefault();
  const openInNewWindow = ['twitter', 'facebook'];
  if (service && openInNewWindow.includes(service)) {
    if (! window.open(
      href,
      't',
      'toolbar=0,resizable=1,scrollbars=1,status=1,width=600,height=400'
    )) {
      window.location = href;
    }
  } else if ('print' === service) {
    // Handle print service click.
    window.print();
  } else if ('comments' === service) {
    // Load Facebook SDK if not already available.
    if (
      'undefined' === typeof window.FB &&
      'undefined' !== typeof window.nbc.facebookAppId
    ) {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: window.nbc.facebookAppId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v10.0',
        });
      };
      const sdk = document.createElement('script');
      const lastScript = document.getElementsByTagName('script')[0];
      sdk.src = 'https://connect.facebook.net/en_US/sdk.js';
      sdk.async = true;
      lastScript.parentNode.insertBefore(sdk, lastScript);
    }

    // Open comments pane.
    document.body.classList.add('comments-open');
  } else {
    window.location = href;
  }
};

export default onServiceClick;
