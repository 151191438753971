import { Component } from 'js-component-framework';
import onServiceClick from './onServiceClick';

/**
 * Component for Share Button
 */
export default class ShareButton extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    this.commentsCloseTrigger = document
      .querySelector('.facebook-comments__close');

    this.setTriggers();
  }

  /**
   * Set event listener on each service anchor link.
   */
  setTriggers() {
    if (this.commentsCloseTrigger) {
      this.commentsCloseTrigger.addEventListener('click', () => {
        document.body.classList.remove('comments-open');
      });
    }

    Array.prototype.forEach.call(this.children.items, (item) => {
      item.addEventListener('click', (event) => {
        onServiceClick(event, item.dataset.service, item.href);
      });
    });
  }
}
