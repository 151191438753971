import Article from './article';

const articleConfig = {
  name: 'article',
  class: Article,
  querySelector: {},
  querySelectorAll: {
    pullQuotes: '.wp-block-pullquote',
    figures: '.rich-text .wp-block-image',
  },
  options: {},
};

export default articleConfig;
