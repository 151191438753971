import ArticleMeta from './article-meta';

const articleMetaConfig = {
  name: 'articleMeta',
  class: ArticleMeta,
  querySelector: {},
  options: {},
};

export default articleMetaConfig;
