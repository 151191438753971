import { Component } from 'js-component-framework';
import ScrollMagic from 'scrollmagic';
import isTabletView from 'utils/isTabletView';

/**
 * Component for Video Flyout
 */
export default class VideoFlyout extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    if (! isTabletView()) {
      return;
    }

    this.controller = new ScrollMagic.Controller();
    this.bindEvents();
    this.handleVideoFlyout();
  }

  bindEvents() {
    const dismissButton = document.querySelector('.video-flyout__dismiss');
    dismissButton.addEventListener('click', this.handleDismiss);

    // This will attempt to set the height of the video container in order to prevent
    // a jump in the content when scrolling. 'load' is being used because the video
    // needs time to load.
    window.addEventListener('load', () => {
      this.element.style.height = `${this.element.clientHeight}px`;
      this.element.childNodes[1].childNodes[1].style.position = 'relative';
    });
  }

  handleVideoFlyout() {
    new ScrollMagic.Scene({
      triggerElement: this.element,
      triggerHook: 0, // Show when element top hits top of viewport...
      offset: 150, // and then scrolls another 150px, approx 35% off canvas
    })
      .setClassToggle(this.element, 'video-flyout--fixed')
      .addTo(this.controller);
  }

  handleDismiss() {
    const flyout = this.closest('.video-flyout');
    if (flyout.classList.contains('video-flyout--fixed')) { // eslint-disable-line max-len
      flyout.classList.remove('video-flyout--fixed');
      flyout.classList.add('video-flyout--dismissed');
    }
  }
}
