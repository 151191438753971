import { Component } from 'js-component-framework';
import ScrollMagic from 'scrollmagic';
import isMobileView from 'utils/isMobileView';

/**
 * Component for Article
 */
export default class Article extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    if (this.children.pullQuotes.length) {
      this.controller = new ScrollMagic.Controller();
      this.handlePullQuotes();
    }

    if (this.children.figures.length) {
      this.handleSponsorImages();
    }
  }

  /**
   * Set CSS classes on pull quotes not in view.
   *
   * @param {object} pullQuote
   * @returns {string}
   */
  setCssClasses(pullQuote) {
    // Always fade in from left on mobile.
    if (isMobileView()) {
      pullQuote.classList.add('move-left');
      return 'fade-in-left-right';
    }

    if (this.hasClass(pullQuote, 'alignright')) {
      pullQuote.classList.add('move-right');
      return 'fade-in-left-right';
    }

    if (this.hasClass(pullQuote, 'alignleft')) {
      pullQuote.classList.add('move-left');
      return 'fade-in-left-right';
    }

    pullQuote.classList.add('move-down');
    return 'fade-in-up';
  }

  /**
   * Set pull quote animations.
   */
  handlePullQuotes() {
    this.children.pullQuotes
      .forEach((pullQuote) => {
        if (! this.isVisible(pullQuote)) {
          const defaultClass = this.setCssClasses(pullQuote);

          this.scrollMagicFactory(pullQuote, defaultClass);
        }
      });
  }

  /**
   * Create instances of ScrollMagic.
   *
   * @param {object} pullQuote
   * @param {string} className
   */
  scrollMagicFactory(pullQuote, className) {
    new ScrollMagic.Scene({
      triggerElement: pullQuote,
      triggerHook: 'onEnter',
      duration: '0%',
      offset: 70,
    })
      .setClassToggle(pullQuote, className)
      .update(true)
      .reverse(false)
      .addTo(this.controller);
  }

  /**
   * Check if element has a CSS class.
   *
   * @param {object} element
   * @param {string} className
   * @returns {boolean}
   */
  hasClass = (element, className) => element.classList.contains(className);

  /**
   * Check if an element is in the viewport.
   *
   * @param {object} element
   * @returns {boolean}
   */
  isVisible = (element) => {
    const topPosition = element.getBoundingClientRect().top;
    const windowHeight = document.documentElement.clientHeight;

    return 0 >= topPosition - windowHeight;
  };

  /**
   * Handle imported sponsor images.
   */
  handleSponsorImages() {
    this.children.figures
      .forEach((figure) => {
        const image = figure.querySelector('img');
        if (! image) {
          return;
        }

        const src = image.getAttribute('src');
        if (src.includes('120*120')) {
          figure.classList.add('sponsor-logo');
          figure.classList.add('alignleft');
        }
      });
  }
}
