import VideoFlyout from './video-flyout';

const videoFlyoutConfig = {
  name: 'videoFlyout',
  class: VideoFlyout,
  querySelector: {
    video: '.article-featured-media',
  },
  options: {},
};

export default videoFlyoutConfig;
