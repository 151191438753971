import ShareButton from './share-button';

const shareButtonsConfig = {
  name: 'sharingBar',
  class: ShareButton,
  querySelector: {},
  querySelectorAll: {
    items: '.share-buttons a',
  },
  options: {},
};

export default shareButtonsConfig;
